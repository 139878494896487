import React, { useState, useEffect } from "react";
import "../assets/css/navbar.css";
import {
  faBars,
  faClose,
  faAngleDown,
  faAngleUp,
  faGear,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, SwipeableDrawer } from "@mui/material";
import blogo from "../assets/images/digineous2.png";

function NavBar() {
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [plantArchitectureOpen, setPlantArchitectureOpen] = useState(false);

  const [UATOpen, setUATOpen] = useState(false);
  const [rawDataOpen, setRawDataOpen] = useState(false);
  const [dashboardopen, setDashboardOpen] = useState(false);
  const [productionOpen, setProductionOpen] = useState(false);
  const [method2Open, setMethod2Open] = useState(false);
  const [method1Open, setMethod1Open] = useState(false);
  const [report1, setReport1Open] = useState(false);

  const [report2, setReport2Open] = useState(false);
  const [userId, setUserId] = useState(null);
  const [administrativeOpen, setAdministrativeOpen] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });

  const navigate = useNavigate();

  const handlePlantArchitectureClick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(!plantArchitectureOpen);
  };
  const handleAdministrativeClick = () => {
    setAdministrativeOpen(!administrativeOpen);
   
    setMethod1Open(false);
    setMethod2Open(false);
    setProductionOpen(false);
    
  };

  const handleUATClick = () => {
    setUATOpen(!UATOpen);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
  };

  const handleMethod2CLick = () => {
    setMethod2Open(!method2Open);
    setUATOpen(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setOpen(false);
  };
  const handleMethod1CLick = () => {
    setMethod1Open(!method1Open);
    setUATOpen(false);
    setMethod2Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setOpen(false);
    setReport1Open(false);
    setReport2Open(false);
  };

  const handleProductionLick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setOpen(false);
    setRawDataOpen(false);
    setReport1Open(false);
    setReport2Open(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(!productionOpen);
  };
  const handleReportClick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    // setMethod1Open(false);
    setReport2Open(false);
    setRawDataOpen(false);
    setOpen(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(false);
    setReport1Open(!report1);
  };
  const handleDashboardClick = () => {
    setUATOpen(false);
    // setMethod2Open(false);
    // setMethod1Open(false);
    setReport1Open(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(false);
    setReport2Open(false);
    setDashboardOpen(!dashboardopen);
    setOpen(false);
  };
  const handleReport2Click = () => {
    setUATOpen(false);
    // setMethod2Open(false);
    setMethod1Open(false);
    setReport1Open(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(false);
    setOpen(false);
    setReport2Open(!report2);
  };
  const handleRawDataClick = () => {
    setRawDataOpen(!rawDataOpen);
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setPlantArchitectureOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };
  const userID = localStorage.getItem("userID");
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    navigate("/login");
  };
  const handleHideMenu = () => {
    setHideMenu(!hideMenu);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSettingsClick = () => {
    setOpen(!open);
    setDashboardOpen(false);
    setMethod1Open(false);
    setReport1Open(false);
    setReport2Open(false);
    setMethod1Open(false);
    setMethod2Open(false);
    setProductionOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ right: open });
  };

  return (
    <nav>
      <div>
        <Link className="clogo" to="/welcome">
          <img
            src={blogo}
            alt="Customer Logo"
            style={{ width: "140px", height: "45px" }}
          />
        </Link>
      </div>

      <div className="seticon">
        <FontAwesomeIcon
          style={{ fontSize: "25px", padding: "0px 40px", cursor: "pointer" }}
          icon={faBars}
          // onClick={toggleSidebar}
          onClick={toggleDrawer(true)}
        />
      </div>

      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className={`sidebar ${toggleDrawer(true) ? "open" : ""}`}>
          <List>
            <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/raw_data"
              className={
                location.pathname === "/raw_data" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Raw Data" />
            </ListItem>

            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleMethod1CLick}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Method 1" />

              {method1Open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={method1Open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  style={{ cursor: "pointer" }}
                  Button
                  onClick={handleDashboardClick}
                >
                  <ListItemText primary="Dashboard" />
                  {dashboardopen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={dashboardopen}
                  timeout="auto"
                  unmountOnExit
                  disablePadding
                >
                  <List component="div" disablePadding>
                    <ListItem
                      Button
                      component={Link}
                      to="/dashboard/oeedashboard"
                      className={
                        location.pathname === "/dashboard/oeedashboard"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="OEE" />
                    </ListItem>
                    <ListItem
                      Button
                      component={Link}
                      to="/dashboard/iconicview"
                      className={
                        location.pathname === "/dashboard/iconicview"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Iconic View" />
                    </ListItem>
                    {/* <ListItem
                Button
                component={Link}
                to="/dashboard/tabularview"
                className={
                  location.pathname === "/dashboard/tabularview"
                    ? "activeListItem"
                    : ""
                }
              >
                <ListItemText primary="Tabular View" />
              </ListItem> */}
                    <ListItem
                      Button
                      component={Link}
                      to="/dashboard/machinestatus"
                      className={
                        location.pathname === "/dashboard/machinestatus"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Machine Status" />
                    </ListItem>
                  </List>
                </Collapse>
                {userID !== "userpth" && (
                  <ListItem
                    Button
                    component={Link}
                    to="/method1/ghaziabad_ope_1"
                    className={
                      location.pathname === "/method1/ghaziabad_ope_1"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Ghaziabad OPE" />
                  </ListItem>
                )}
                {userID !== "usergzb" && (
                  <ListItem
                    Button
                    component={Link}
                    to="/method1/pathredi_ope_1"
                    className={
                      location.pathname === "/method1/pathredi_ope_1"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Pathredi OPE" />
                  </ListItem>
                )}
              </List>
              <ListItem
                style={{ cursor: "pointer" }}
                Button
                onClick={handleReportClick}
              >
                <ListItemText primary="Reports " />

                {report1 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={report1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    Button
                    component={Link}
                    to="/reportm1/hour_bucket_m1"
                    className={
                      location.pathname === "/reportm1/hour_bucket_m1"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Hour Bucket " />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/reportm1/daily"
                    className={
                      location.pathname === "/reportm1/daily"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Daily" />
                  </ListItem>
                  {/* <ListItem
                    Button
                    component={Link}
                    to="/reportm1/wholedayreportm1"
                    className={
                      location.pathname === "/reportm1/wholedayreportm1"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Whole Day" />
                  </ListItem> */}
                  <ListItem
                    Button
                    component={Link}
                    to="/reportm1/weekly"
                    className={
                      location.pathname === "/reportm1/weekly"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Weekly" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/reportm1/monthly"
                    className={
                      location.pathname === "/reportm1/monthly"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Monthly" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/reportm1/quaterly"
                    className={
                      location.pathname === "/reportm1/quaterly"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Quaterly" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/reportm1/yearly"
                    className={
                      location.pathname === "/reportm1/yearly"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Yearly" />
                  </ListItem>
                </List>
              </Collapse>
            </Collapse>
            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleMethod2CLick}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Method 2" />

              {method2Open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={method2Open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              <List component="div" disablePadding>
                <ListItem
                  style={{ cursor: "pointer" }}
                  Button
                  onClick={handleDashboardClick}
                >
                  <ListItemText primary="Dashboard" />
                  {dashboardopen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={dashboardopen}
                  timeout="auto"
                  unmountOnExit
                  disablePadding
                >
                  <List component="div" disablePadding>
                    <ListItem
                      Button
                      component={Link}
                      to="/dashboard/oeedashboardm2"
                      className={
                        location.pathname === "/dashboard/m2/oeedashboardm2"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="OEE" />
                    </ListItem>
                    <ListItem
                      Button
                      component={Link}
                      to="/dashboard/iconicviewm2"
                      className={
                        location.pathname === "/dashboard/m2/iconicviewm2"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Iconic View" />
                    </ListItem>
                    {/* <ListItem
                Button
                component={Link}
                to="/dashboard/tabularview"
                className={
                  location.pathname === "/dashboard/tabularview"
                    ? "activeListItem"
                    : ""
                }
              >
                <ListItemText primary="Tabular View" />
              </ListItem> */}
                  
                  </List>
                </Collapse>
               
              </List>
                <ListItem
                  Button
                  component={Link}
                  to="/method2/machine_input"
                  className={
                    location.pathname === "/method2/machine_input"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Machine Input" />
                </ListItem>
                {userID !== "userpth" && (
                  <ListItem
                    Button
                    component={Link}
                    to="/method2/ghaziabad_ope_2"
                    className={
                      location.pathname === "/method2/ghaziabad_ope_2"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Ghaziabad OPE " />
                  </ListItem>
                )}
                {userID !== "usergzb" && (
                  <ListItem
                    Button
                    component={Link}
                    to="/method2/pathredi_ope_2"
                    className={
                      location.pathname === "/method2/pathredi_ope_2"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Pathredi OPE" />
                  </ListItem>
                )}
              </List>
              <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleReport2Click}
            >
            
              <ListItemText primary="Reports " />

              {report2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={report2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/hour_bucket_m2"
                  className={
                    location.pathname === "/reportm2/hour_bucket_m2"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Hourly Bucket " />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/daily"
                  className={
                    location.pathname === "/reportm2/daily"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Daily" />
                </ListItem>
                {/* <ListItem
                  Button
                  component={Link}
                  to="/reportm2/wholedayreportm2"
                  className={
                    location.pathname === "/reportm2/wholedayreportm2"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Whole Day" />
                </ListItem> */}
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/weekly"
                  className={
                    location.pathname === "/reportm2/weekly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Weekly " />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/monthly"
                  className={
                    location.pathname === "/reportm2/monthly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Monthly" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/quaterly"
                  className={
                    location.pathname === "/reportm2/quaterly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Quaterly" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/yearly"
                  className={
                    location.pathname === "/reportm2/yearly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Yearly" />
                </ListItem>
              </List>
            </Collapse>
            </Collapse>

            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleProductionLick}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Production Master" />

              {productionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={productionOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/part_master"
                  className={
                    location.pathname === "/production_plan/part_master"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Part Master" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/frdc_master"
                  className={
                    location.pathname === "/production_plan/frdc_master"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Change Over Master" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/unknown_loss"
                  className={
                    location.pathname === "/production_plan/unknown_loss"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Unknown Loss" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/quality_rejection"
                  className={
                    location.pathname === "/production_plan/quality_rejection"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Quality Rejection " />
                </ListItem>
                {/* <ListItem
                  Button
                  component={Link}
                  to="/production_plan/machine_downtime"
                  className={
                    location.pathname === "/production_plan/machine_downtime"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Machine Downtime" />
                </ListItem> */}

              
              </List>
            </Collapse>
            <ListItem Button sx={{cursor:'pointer'}} onClick={handleAdministrativeClick}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Administrative" />
              {administrativeOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={administrativeOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  Button
                  component={Link}
                  to="/plant_architecture/plant_master"
                  className={location.pathname === "/plant_architecture/plant_master" ? "activeListItem" : ""}
                >
                  <ListItemText primary="Plant Master" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/plant_architecture/machine_master"
                  className={location.pathname === "/plant_architecture/machine_master" ? "activeListItem" : ""}
                >
                  <ListItemText primary="Machine Master" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/plant_architecture/device_status"
                  className={location.pathname === "/plant_architecture/device_status" ? "activeListItem" : ""}
                >
                  <ListItemText primary="Device Status" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/plant_architecture/shift_master"
                  className={location.pathname === "/plant_architecture/shift_master" ? "activeListItem" : ""}
                >
                  <ListItemText primary="Shift Master" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/plant_architecture/holiday_list"
                  className={location.pathname === "/plant_architecture/holiday_list" ? "activeListItem" : ""}
                >
                  <ListItemText primary="Holiday List" />
                </ListItem>
              </List>
            </Collapse>


          
            <ListItem
              onClick={handleLogout} // Attach the logout function here
              Button
              className={
                location.pathname === "/logout" ? "activeListItem" : ""
              }
              style={{ cursor: "pointer", marginBottom: "30px" }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOut} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </nav>
  );
}

export default NavBar;
