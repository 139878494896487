// import React, { useEffect, useState } from "react";
// import {
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   Box,
//   TextField,
//   Button,
//   Grid,
//   tableCellClasses,
//   styled,
//   Snackbar,
// } from "@mui/material";
// import {
//   Table,
//   TableHead,
//   TableBody,
//   TableRow,
//   TableCell,
//   TablePagination,
//   CircularProgress,
// } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { apigetMachine } from "../api/apigetmachine";
// import { apigetLines } from "../api/api.getline";
// import { apiHourlyBucket1 } from "../api/api.hourlybucket1";
// import { parseISO, format } from "date-fns";
// import { apiGetShift } from "../api/api.getshift";
// import MuiAlert from "@mui/material/Alert";
// import { useAuthCheck } from "../utils/Auth";
// import DownloadButton from "../utils/DownloadButton";
// import { useNavigate } from "react-router-dom";

// // import styled from "styled-components";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#1FAEC5",
//     color: theme.palette.common.white,
//     position: "sticky",
//     top: 0,
//     zIndex: 1,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));
// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// export default function HourlyBucketM1() {
//   const [refreshData, setRefreshData] = useState(false);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [machineData, setMachineData] = useState([]);
//   const [lineData, setLineData] = useState([]);
//   const [error, setError] = useState(null);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [shiftData, setShiftData] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const [hourlyBucket, setHourlyBucket] = useState({
//     lineNo: "",
//     machineId: "",
//     fromDate: "23:01:1997",
//     shiftNo: "",
//   });
//   const navigate = useNavigate();

//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   const [severity, setSeverity] = useState("success");
//   const [selectedLine, setSelectedLine] = useState("");
//   const [data, setData] = useState([]);

//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };
//   useAuthCheck();
//   useEffect(() => {
//     const getmachine = async () => {
//       try {
//         const result = await apigetMachine();
//         console.log("Result data machine:", result.data.data);
//         setMachineData(result.data.data); 
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getmachine();
//   }, [refreshData]);
//   useEffect(() => {
//     const getLine = async () => {
//       try {
//         const result = await apigetLines();
//         console.log("Result data line:", result.data.data);
//         setLineData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getLine();
//   }, [refreshData]);
//   useEffect(() => {
//     const getShift = async () => {
//       try {
//         const result = await apiGetShift();
//         console.log("shiftdata", result.data.data);
//         setShiftData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getShift();
//   }, [refreshData]);

//   const handleInputChange = (e) => {
//     console.log(e.target.name, e.target.value);
//     const { name, value } = e.target;
//     setHourlyBucket((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//     if (name === "lineNo") {
//       setSelectedLine(value);
//     }
//   };
//   const filteredMachines = machineData.filter(
//     (machine) => machine.lineNo === selectedLine
//   );
//   const handleAddSubmit = async (event) => {
//     event.preventDefault();
//     setLoading(true);
//     try {
//       const formattedFromDate = format(
//         parseISO(hourlyBucket.fromDate),
//         "dd-MMM-yyyy"
//       );

//       console.log(
//         ",fromdate,machineid,lineid,shiftid:",

//         formattedFromDate
//       );
//       const formattedRawData = {
//         ...hourlyBucket,
//         fromDate: formattedFromDate,
//       };
//       // setAddOpen(false);
//       console.log("hourly 1 data:", formattedRawData);
//       const result = await apiHourlyBucket1(formattedRawData);

//       // await getmachine();
//       handleSnackbarOpen(
//         "Hourly bucket 1 data fetched successfully!",
//         "success"
//       );
//       // setLoading(false);
//       console.log("hourly1 response", result.data);
//       setData(result.data);
//       setRefreshData((prev) => !prev);
//     } catch (error) {
//       console.error("Error getting hourly bucket 1 data:", error);
//       handleSnackbarOpen(
//         "Error fetching hourly bucket 1  data. Please try again.",
//         "error"
//       );
//     } finally {
//       setLoading(false);
//     }
//   };
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };
//   const downloadApiCall = async () => {
//     const { lineNo, machineId, fromDate, shiftNo } = hourlyBucket;
//     const formattedFromDate = format(parseISO(fromDate), "dd-MMM-yyyy");

//     return await apiHourlyBucket1({
//       lineNo,
//       machineId,
//       fromDate: formattedFromDate,
//       shiftNo,
//     });
//   };

//   const formatData = (data) => {
//     return data.map((row) => ({
//       "M Id": row.machineID ?? "",
//       "Date Time": row.dateTime ?? "",
//       VAT: row.vat != null ? parseFloat(row.vat) : "",
//       "Avg CT": row.avgSct != null ? parseFloat(row.avgSct) : "",
//       "U Loss": row.uLoss != null ? parseFloat(row.uLoss) : "",
//       "Revised U Loss":
//         row.revisedULoss != null ? parseFloat(row.revisedULoss) : "",
//       "U%": row.uPer != null ? parseFloat(row.uPer) : "",
//       "A Loss": row.aLoss != null ? parseFloat(row.aLoss) : "",
//       "Revised A Loss":
//         row.revisedALoss != null ? parseFloat(row.revisedALoss) : "",
//       "A%": row.aPer != null ? parseFloat(row.aPer) : "",
//       "P Loss": row.pLoss != null ? parseFloat(row.pLoss) : "",
//       "P %": row.pPer != null ? parseFloat(row.pPer) : "",
//       "Q Loss ": row.qLoss != null ? parseFloat(row.qLoss) : "",
//       "Q% ": row.qPer != null ? parseFloat(row.qPer) : "",
//       Total: row.total != null ? parseFloat(row.total) : "",
//       "OPEC1% ": row.opeC1 != null ? parseFloat(row.opeC1) : "",
//       "OPEC2% ": row.opeC2 != null ? parseFloat(row.opeC2) : "",
//       "OEE%": row.oee != null ? parseFloat(row.oee) : "",
//     }));
//   };

//   const handleNavigateWholeDayM1 = () => {
//     const { fromDate, lineNo, machineId } = hourlyBucket;
//     const selectedMachine = machineData.find(
//       (machine) => machine.machineId === machineId
//     );
//     const machineNo = selectedMachine ? selectedMachine.machineNo : "";

//     navigate("/reportm1/wholedayreportm1", {
//       state: { fromDate, lineNo, machineId, machineNo },
//     });
//   };
//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };
//   const emptyRows =
//     rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
//   return (
//     <div style={{ padding: "0px 20px", width: "100%" }}>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-start",
//           paddingTop: "20px",
//           paddingBottom: "10px",
//         }}
//       >
//         <h2>Hour Bucket (M1)</h2>
//       </div>
//       <Grid
//         container
//         spacing={2}
//         style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
//       >
//         {" "}
//         <Grid item xs={6} sm={3}>
//           {" "}
//           <FormControl sx={{ minWidth: 250 }}>
//             <TextField
//               name="fromDate"
//               label="Select Date"
//               type="date"
//               // defaultValue="2024-03-20T09:00"
//               value={hourlyBucket?.fromDate}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           {" "}
//           <FormControl sx={{ minWidth: 250 }}>
//             <InputLabel>Select Plant</InputLabel>
//             <Select
//               name="lineNo"
//               value={hourlyBucket?.lineNo}
//               onChange={handleInputChange}
//             >
//               {lineData.map((line) => (
//                 <MenuItem key={line.id} value={line.lineNo}>
//                   {line.lineName}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           {" "}
//           <FormControl sx={{ minWidth: 250 }}>
//             <InputLabel>Select Machine</InputLabel>
//             <Select
//               name="machineId"
//               value={hourlyBucket?.machineId}
//               onChange={handleInputChange}
//             >
//               {filteredMachines.map((machine) => (
//                 <MenuItem key={machine.id} value={machine.machineId}>
//                   {machine.displayMachineName}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           {" "}
//           <FormControl sx={{ minWidth: 250 }}>
//             <InputLabel>Select Shift</InputLabel>
//             <Select
//               name="shiftNo"
//               value={hourlyBucket?.value}
//               onChange={handleInputChange}
//             >
//               <MenuItem value="1">Shift A</MenuItem>
//               <MenuItem value="2">Shift B</MenuItem>
//               <MenuItem value="3">Shift C</MenuItem>
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item>
//           <Button variant="contained" color="primary" onClick={handleAddSubmit}>
//             OK
//           </Button>
//         </Grid>
//       </Grid>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           // marginBottom: "10px",
//         }}
//       >
//         <DownloadButton
//           apiCall={downloadApiCall}
//           formatData={formatData}
//           fileName="HourlyBucket(M1).xlsx"
//         />
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleNavigateWholeDayM1}
//           style={{ marginLeft: "10px" }}
//         >
//           Whole Day
//         </Button>
//       </div>
//       <TablePagination
//         rowsPerPageOptions={[5, 10, 25, 1000]}
//         component="div"
//         count={data.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />

//       <Box sx={{  maxHeight: "500px", overflow: "auto" ,marginBottom:'40px'}}>
//         {loading ? (
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "100vh",
//             }}
//           >
//             <CircularProgress />
//           </Box>
//         ) : (
//           <Table
//             size="small"
//             style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
//           >
//             <TableHead>
//               <TableRow>
//                 <StyledTableCell className="table-cell">MID</StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {" "}
//                   Date Time{" "}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">VAT</StyledTableCell>
//                 <StyledTableCell className="table-cell">Avg CT</StyledTableCell>
//                 <StyledTableCell className="table-cell">U Loss</StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Revised U Loss
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">U%</StyledTableCell>
//                 <StyledTableCell className="table-cell">A Loss</StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Revised A Loss
//                 </StyledTableCell>{" "}
//                 <StyledTableCell className="table-cell">A%</StyledTableCell>
//                 <StyledTableCell className="table-cell">P Loss</StyledTableCell>
//                 <StyledTableCell className="table-cell">P%</StyledTableCell>
//                 <StyledTableCell className="table-cell">Q Loss</StyledTableCell>
//                 <StyledTableCell className="table-cell">Q %</StyledTableCell>
//                 <StyledTableCell className="table-cell">Total</StyledTableCell>
//                 <StyledTableCell className="table-cell">OPE%</StyledTableCell>
//                 <StyledTableCell className="table-cell">OEE%</StyledTableCell>
//                 <StyledTableCell className="table-cell">Target Production</StyledTableCell>
//                 <StyledTableCell className="table-cell">Actual Production</StyledTableCell>

//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {data
//                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row, index) => (
//                   <StyledTableRow key={index}>
//                     <StyledTableCell className="table-cell">
//                       {row.machineID}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.dateTime}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.vat}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.avgSct}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.uLoss}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.revisedULoss}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.uPer}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.aLoss}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.revisedALoss}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.aPer}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.pLoss}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.pPer}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.qLoss}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.qPer}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.total}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.opeC1}
//                     </StyledTableCell>

//                     <StyledTableCell className="table-cell">
//                       {row.oee}
//                     </StyledTableCell>
//                     <StyledTableCell className="table-cell">
//                       {row.target}
//                     </StyledTableCell>

//                     <StyledTableCell className="table-cell">
//                       {row.actualProduction}
//                     </StyledTableCell>
//                   </StyledTableRow>
//                 ))}
//             </TableBody>
//           </Table>
//         )}
//       </Box>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//       >
//         <MuiAlert
//           onClose={() => setOpenSnackbar(false)}
//           severity={severity}
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </div>
//   );
// }



import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Button,
  Grid,
  tableCellClasses,
  styled,
  Snackbar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  CircularProgress,
  TableContainer,
  Paper,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { apigetMachine } from "../api/apigetmachine";
import { apigetLines } from "../api/api.getline";
import { apiHourlyBucket1 } from "../api/api.hourlybucket1";
import { parseISO, format } from "date-fns";
import { apiGetShift } from "../api/api.getshift";
import MuiAlert from "@mui/material/Alert";
import { useAuthCheck } from "../utils/Auth";
import DownloadButton from "../utils/DownloadButton";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 4px',
      fontSize: '0.8rem',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 4px',
      fontSize: '0.75rem',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  [theme.breakpoints.down('sm')]: {
    '& > *': {
      whiteSpace: 'nowrap',
    },
  },
}));

const ResponsiveFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  minWidth: 'unset',
  marginBottom: theme.spacing(2),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > button': {
      width: '100%',
    },
  },
}));

export default function HourlyBucketM1() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [refreshData, setRefreshData] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [shiftData, setShiftData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [hourlyBucket, setHourlyBucket] = useState({
    lineNo: "",
    machineId: "",
    fromDate: "23:01:1997",
    shiftNo: "",
  });
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [severity, setSeverity] = useState("success");
  const [selectedLine, setSelectedLine] = useState("");
  const [data, setData] = useState([]);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  useAuthCheck();
  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("Result data machine:", result.data.data);
        setMachineData(result.data.data); 
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getmachine();
  }, [refreshData]);
  useEffect(() => {
    const getLine = async () => {
      try {
        const result = await apigetLines();
        console.log("Result data line:", result.data.data);
        setLineData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getLine();
  }, [refreshData]);
  useEffect(() => {
    const getShift = async () => {
      try {
        const result = await apiGetShift();
        console.log("shiftdata", result.data.data);
        setShiftData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getShift();
  }, [refreshData]);

  const handleInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    setHourlyBucket((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  const filteredMachines = machineData.filter(
    (machine) => machine.lineNo === selectedLine
  );
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formattedFromDate = format(
        parseISO(hourlyBucket.fromDate),
        "dd-MMM-yyyy"
      );

      console.log(
        ",fromdate,machineid,lineid,shiftid:",

        formattedFromDate
      );
      const formattedRawData = {
        ...hourlyBucket,
        fromDate: formattedFromDate,
      };
      // setAddOpen(false);
      console.log("hourly 1 data:", formattedRawData);
      const result = await apiHourlyBucket1(formattedRawData);

      // await getmachine();
      handleSnackbarOpen(
        "Hourly bucket 1 data fetched successfully!",
        "success"
      );
      // setLoading(false);
      console.log("hourly1 response", result.data);
      setData(result.data);
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error getting hourly bucket 1 data:", error);
      handleSnackbarOpen(
        "Error fetching hourly bucket 1  data. Please try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const downloadApiCall = async () => {
    const { lineNo, machineId, fromDate, shiftNo } = hourlyBucket;
    const formattedFromDate = format(parseISO(fromDate), "dd-MMM-yyyy");

    return await apiHourlyBucket1({
      lineNo,
      machineId,
      fromDate: formattedFromDate,
      shiftNo,
    });
  };

  const formatData = (data) => {
    return data.map((row) => ({
      "M Id": row.machineID ?? "",
      "Date Time": row.dateTime ?? "",
      VAT: row.vat != null ? parseFloat(row.vat) : "",
      "Avg CT": row.avgSct != null ? parseFloat(row.avgSct) : "",
      "U Loss": row.uLoss != null ? parseFloat(row.uLoss) : "",
      "Revised U Loss":
        row.revisedULoss != null ? parseFloat(row.revisedULoss) : "",
      "U%": row.uPer != null ? parseFloat(row.uPer) : "",
      "A Loss": row.aLoss != null ? parseFloat(row.aLoss) : "",
      "Revised A Loss":
        row.revisedALoss != null ? parseFloat(row.revisedALoss) : "",
      "A%": row.aPer != null ? parseFloat(row.aPer) : "",
      "P Loss": row.pLoss != null ? parseFloat(row.pLoss) : "",
      "P %": row.pPer != null ? parseFloat(row.pPer) : "",
      "Q Loss ": row.qLoss != null ? parseFloat(row.qLoss) : "",
      "Q% ": row.qPer != null ? parseFloat(row.qPer) : "",
      Total: row.total != null ? parseFloat(row.total) : "",
      "OPEC1% ": row.opeC1 != null ? parseFloat(row.opeC1) : "",
      "OPEC2% ": row.opeC2 != null ? parseFloat(row.opeC2) : "",
      "OEE%": row.oee != null ? parseFloat(row.oee) : "",
    }));
  };

  const handleNavigateWholeDayM1 = () => {
    const { fromDate, lineNo, machineId } = hourlyBucket;
    const selectedMachine = machineData.find(
      (machine) => machine.machineId === machineId
    );
    const machineNo = selectedMachine ? selectedMachine.machineNo : "";

    navigate("/reportm1/wholedayreportm1", {
      state: { fromDate, lineNo, machineId, machineNo },
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <Box sx={{ 
      padding: { xs: '10px', sm: '20px' }, 
      width: '100%',
      maxWidth: '100vw',
      overflowX: 'hidden' 
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-start',
        paddingY: { xs: 1, sm: 1 },
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'start', sm: 'center' },
      }}>
        <h2>Hour Bucket (M1)</h2>
      </Box>

      <Grid container spacing={2} sx={{ mb: 1 }} alignItems="center">
        <Grid item xs={12} sm={6} md={2.3}>
          <ResponsiveFormControl>
            <TextField
              name="fromDate"
              label="Select Date"
              type="date"
              value={hourlyBucket?.fromDate}
              onChange={handleInputChange}
              fullWidth
            />
          </ResponsiveFormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2.3}>
          <ResponsiveFormControl>
            <InputLabel>Select Plant</InputLabel>
            <Select
              name="lineNo"
              value={hourlyBucket?.lineNo}
              onChange={handleInputChange}
              fullWidth
            >
              {lineData.map((line) => (
                <MenuItem key={line.id} value={line.lineNo}>
                  {line.lineName}
                </MenuItem>
              ))}
            </Select>
          </ResponsiveFormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2.3}>
          <ResponsiveFormControl>
            <InputLabel>Select Machine</InputLabel>
            <Select
              name="machineId"
              value={hourlyBucket?.machineId}
              onChange={handleInputChange}
              fullWidth
            >
              {filteredMachines.map((machine) => (
                <MenuItem key={machine.id} value={machine.machineId}>
                  {machine.displayMachineName}
                </MenuItem>
              ))}
            </Select>
          </ResponsiveFormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2.3}>
          <ResponsiveFormControl>
            <InputLabel>Select Shift</InputLabel>
            <Select
              name="shiftNo"
              value={hourlyBucket?.value}
              onChange={handleInputChange}
              fullWidth
            >
              <MenuItem value="1">Shift A</MenuItem>
              <MenuItem value="2">Shift B</MenuItem>
              <MenuItem value="3">Shift C</MenuItem>
            </Select>
          </ResponsiveFormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2.3} sx={{mb:2}}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAddSubmit}
            fullWidth={isMobile}
          >
            OK
          </Button>
        </Grid>
      </Grid>

      <ButtonContainer>
        <DownloadButton
          apiCall={downloadApiCall}
          formatData={formatData}
          fileName="HourlyBucket(M1).xlsx"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNavigateWholeDayM1}
        >
          Whole Day
        </Button>
      </ButtonContainer>

      <Box sx={{ 
        width: '100%',
        overflow: 'hidden',
        mb: 0
      }}>
        <TablePagination
          rowsPerPageOptions={[50, 100, 500, 1000]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <TableContainer 
        component={Paper} 
        sx={{ 
          maxHeight: { xs: '400px', sm: '500px' },
          mb: 5,
          overflow: 'auto'
        }}
      >
        {loading ? (
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
          }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table
            size={isMobile ? "small" : "medium"}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>MID</StyledTableCell>
                <StyledTableCell>Date Time</StyledTableCell>
                <StyledTableCell>VAT</StyledTableCell>
                <StyledTableCell>Avg CT</StyledTableCell>
                <StyledTableCell>U Loss</StyledTableCell>
                <StyledTableCell>Rev. U Loss</StyledTableCell>
                <StyledTableCell>U%</StyledTableCell>
                <StyledTableCell>A Loss</StyledTableCell>
                <StyledTableCell>Rev. A Loss</StyledTableCell>
                <StyledTableCell>A%</StyledTableCell>
                <StyledTableCell>P Loss</StyledTableCell>
                <StyledTableCell>P%</StyledTableCell>
                <StyledTableCell>Q Loss</StyledTableCell>
                <StyledTableCell>Q%</StyledTableCell>
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell>OPE%</StyledTableCell>
                <StyledTableCell>OEE%</StyledTableCell>
                <StyledTableCell>Target</StyledTableCell>
                <StyledTableCell>Actual</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.machineID}</StyledTableCell>
                    <StyledTableCell>{row.dateTime}</StyledTableCell>
                    <StyledTableCell>{row.vat}</StyledTableCell>
                    <StyledTableCell>{row.avgSct}</StyledTableCell>
                    <StyledTableCell>{row.uLoss}</StyledTableCell>
                    <StyledTableCell>{row.revisedULoss}</StyledTableCell>
                    <StyledTableCell>{row.uPer}</StyledTableCell>
                    <StyledTableCell>{row.aLoss}</StyledTableCell>
                    <StyledTableCell>{row.revisedALoss}</StyledTableCell>
                    <StyledTableCell>{row.aPer}</StyledTableCell>
                    <StyledTableCell>{row.pLoss}</StyledTableCell>
                    <StyledTableCell>{row.pPer}</StyledTableCell>
                    <StyledTableCell>{row.qLoss}</StyledTableCell>
                    <StyledTableCell>{row.qPer}</StyledTableCell>
                    <StyledTableCell>{row.total}</StyledTableCell>
                    <StyledTableCell>{row.opeC1}</StyledTableCell>
                    <StyledTableCell>{row.oee}</StyledTableCell>
                    <StyledTableCell>{row.target}</StyledTableCell>
                    <StyledTableCell>{row.actualProduction}</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}