import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  tableCellClasses,
  styled,
  TablePagination,
  Snackbar,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Paper,
  Typography,
  TableContainer,
  IconButton,
  Modal
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { apigetMachine } from "../api/apigetmachine";
import { apiGetOpe1 } from "../api/api.getope1";
import MuiAlert from "@mui/material/Alert";
import { useAuthCheck } from "../utils/Auth";
import DownloadButton from "../utils/DownloadButton";
import PartNamesModal from "./PartsNameModal";
import { apiGetPartsName } from "../api/api.getPartsName";
import CloseIcon from '@mui/icons-material/Close';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(today.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

export default function PathrediOpe() {
  
  const [opeData, setOpeData] = useState([]);
  const [machineId, setMachineId] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [lineId, setLineId] = useState("18");
  const [machineData, setMachineData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [lineNo, setLineNo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [partsNames, setPartsNames] = useState({});
  const [selectedPartNames, setSelectedPartNames] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [partNamesData, setPartNamesData] = useState(null);
  const [openPartNamesDialog, setOpenPartNamesDialog] = useState(false);

  const [pathrediOpe1Data, setPathrediOpe2Data] = useState({
    lineNo: "",
    machineId: '',
    fromDate: getCurrentDate(),
    toDate: getCurrentDate(),
  });

  useAuthCheck();
  const handlePartNameClick = async (partId, machineId) => {
    try {
      const result = await apiGetPartsName({ mid: machineId, partId: partId });
      if (result.data.status === "success" && result.data.data.length > 0) {
        setPartNamesData(result.data.data[0]);
        setOpenPartNamesDialog(true);
      } else {
        handleSnackbarOpen("No part names data available", "info");
      }
    } catch (error) {
      handleSnackbarOpen("Error fetching part names: " + error.message, "error");
    }
  };

  const handleClosePartNamesDialog = () => {
    setOpenPartNamesDialog(false);
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("Result data machine:", result.data.data);
        
        let lineNo = null;
        
        const filteredMachines = result.data.data.filter((machine) => {
          console.log("machine.lineNo:", machine.lineNo);
          if (machine.lineName === 'Pathredi') {
            lineNo = machine.lineNo;
          }
          return machine.lineName === 'Pathredi';
        });
        
        if (lineNo !== null) {
          setLineNo(lineNo);
        }
        
        console.log("Filtered machines:", filteredMachines);
        console.log("lineNo for Pathredi:", lineNo);

        setMachineData(filteredMachines);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getmachine();
  }, [refreshData]);
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPathrediOpe2Data((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  const handleMachineInputChange = async (event) => {
    const selectedMachineId = event.target.value;
    setMachineId(selectedMachineId);
    const lineId = lineNo;
    setLoading(true);
    try {
      const result = await apiGetOpe1(lineId, selectedMachineId);
      console.log("Ope Data:", result.data.data);
      setOpeData(result.data.data);
      handleSnackbarOpen("Ope Data fetched successfully!", "success");
    } catch (error) {
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
    }
    finally {
      setLoading(false);
    }
  };

  const handleAddSubmit = async () => {
    const { machineId, fromDate, toDate } = pathrediOpe1Data;
    const lineId = lineNo;
  
    setLoading(true);
    try {
      const result = await apiGetOpe1({ lineId, machineId, fromDate, toDate });
      setOpeData(result.data);
      handleSnackbarOpen("Ope Data fetched successfully!", "success");
    } catch (error) {
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
    } finally {
      setLoading(false);
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const downloadApiCall = async () => {
    const { machineId, fromDate, toDate } = pathrediOpe1Data;
    const lineId = lineNo;

    if (lineId && machineId) {
      try {
        const data = await apiGetOpe1({lineId, machineId, fromDate, toDate});
        return data.data;
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    }
  };
  const handleOpenModal = (partNames) => {
    setSelectedPartNames(partNames);
    setModalOpen(true);
  };
  const formatData = (data) => {
    return data.map((row) => ({
      "M Id": row.mid ?? "",
      "Date Time": row.dateTime ?? "",
      "Ope Period For U": row.cycleTime != null ? parseFloat(row.cycleTime) : "",
      "AD": row.adData ?? "",
      "Seq Of Time Stamp": row.adDiffData ?? "",
      "DCT%": row.dctPercent != null ? parseFloat(row.dctPercent) : "",
      "Loss Type For Ope": row.lossTypeForOpe ?? "",
      "Part A": row.partA != null ? parseFloat(row.partA) : "",
      "Part B": row.partB != null ? parseFloat(row.partB) : "",
      "Part C": row.partC != null ? parseFloat(row.partC) : "",
      "Part D ": row.partD != null ? parseFloat(row.partD) : "",
      "Part E": row.partE != null ? parseFloat(row.partE) : "",
      "Part F ": row.partF ?? "",
      "Part G ": row.partG ?? "",
      "Part H": row.partH ?? "",
      "Part I ": row.partI ?? "",
      "Part J ": row.partJ ?? "",
      "Test": row.test ?? "",
      "Frcd Cycletime ": row.frcdCycleTime != null ? parseFloat(row.frcdCycleTime) : "",
      "F Partname": row.fPartName ?? "",
      "VAT": row.fCycleTime != null ? parseFloat(row.fCycleTime) : "",
      "Category": row.category ?? "",
      "A Loss": row.aLoss != null ? parseFloat(row.aLoss) : "",
      "P Loss": row.pLoss != null ? parseFloat(row.pLoss) : "",
    }));
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, opeData.length - page * rowsPerPage);


  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: "20px",
          paddingBottom: "10px",
        }}
      >
        <h2> Pathredi OPE</h2>
      </div>
      <div style={{display:"flex" ,alignItems:"center",}}>
      <FormControl
        sx={{ width: "30ch", margonTop: "20px", }}
      >
        <InputLabel>Select Machine</InputLabel>
        <Select
          name="machineId"
          value={pathrediOpe1Data.machineId}
          onChange={handleInputChange}
        >
          {machineData.map((id) => (
            <MenuItem key={id.id} value={id.machineId}>
              {id.displayMachineName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 ,marginLeft:2}}>
            <TextField
              label="Start Date"
              name="fromDate"
              type="date"
              value={pathrediOpe1Data.fromDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
        <FormControl sx={{ minWidth: 250 ,marginLeft:2}}>

            <TextField
              label="End Date"
              name="toDate"
              type="date"
              value={pathrediOpe1Data.toDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleAddSubmit} sx={{marginLeft:2,bgcolor:'#4895a1',color:'black'}}>
            OK
          </Button>
        </Grid>
      <div style={{marginLeft:'30px'}}>
      <DownloadButton
        apiCall={downloadApiCall}
        formatData={formatData}
        fileName="Pathredi Ope 1.xlsx"
      />
      </div>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25,1000]}
        component="div"
        count={opeData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Box sx={{ maxHeight: "500px", overflow: "auto", margonTop: "20px",marginBottom:'40px'}}>
      {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
          
        ) : (

        <Table
          size="small"
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
        >
          <TableHead style={{ backgroundColor: "#0002" }}>
            <TableRow width="100%">
              <StyledTableCell className="table-cell">
                Date Time
              </StyledTableCell>
              {/* <StyledTableCell className="table-cell">Time</StyledTableCell> */}
              <StyledTableCell className="table-cell">
                Current Cycle Time in secs
              </StyledTableCell>
             {/* <StyledTableCell className="table-cell">AD</StyledTableCell>*/}
              <StyledTableCell className="table-cell">
                Seq of Time Stamp
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                DCT% 
              </StyledTableCell>
             {/* <StyledTableCell className="table-cell">
               Loss type for OPE
              </StyledTableCell>
              {/* <StyledTableCell className="table-cell">Value Adding Operating Time</StyledTableCell> 
              <StyledTableCell className="table-cell">Part A</StyledTableCell>
              <StyledTableCell className="table-cell">Part B</StyledTableCell>
              <StyledTableCell className="table-cell">Part C</StyledTableCell>
              <StyledTableCell className="table-cell">Part D</StyledTableCell>
              <StyledTableCell className="table-cell">Part E</StyledTableCell>
              <StyledTableCell className="table-cell">Part F</StyledTableCell>
              <StyledTableCell className="table-cell">Part G</StyledTableCell>
              <StyledTableCell className="table-cell">Part H</StyledTableCell>
              <StyledTableCell className="table-cell">Part I</StyledTableCell>
              <StyledTableCell className="table-cell">Part J</StyledTableCell>
              <StyledTableCell className="table-cell">
                Test
              </StyledTableCell>*/}
              {/* <StyledTableCell className="table-cell">Min time</StyledTableCell> */}
              <StyledTableCell className="table-cell">
                Frcd Cycletime
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Partname
              </StyledTableCell>
              {/* <StyledTableCell className="table-cell">F Partname</StyledTableCell> */}
              <StyledTableCell className="table-cell">
                VAT
              </StyledTableCell>
              <StyledTableCell className="table-cell">Category</StyledTableCell>
              <StyledTableCell className="table-cell">A Loss</StyledTableCell>
              <StyledTableCell className="table-cell">P Loss</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {opeData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className="table-cell">
                    {row.dateTime}
                  </StyledTableCell>
                  {/* <StyledTableCell className="table-cell">{row.time}</StyledTableCell> */}
                  <StyledTableCell className="table-cell">
                    {row.cycleTime}
                  </StyledTableCell>
                {/*  <StyledTableCell className="table-cell">
                    {row.adData}
                  </StyledTableCell>*/}
                  <StyledTableCell className="table-cell">
                    {row.adDiffData}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.dctPercent}
                  </StyledTableCell>
             {/*     <StyledTableCell className="table-cell">
                    {row.lossTypeForOpe}
                  </StyledTableCell>
                  {/* <StyledTableCell className="table-cell">{row.valueAddingOperatingTime}</StyledTableCell> 
                  <StyledTableCell className="table-cell">
                    {row.partA}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partB}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partC}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partD}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partE}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partF}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partG}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partH}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partI}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.partJ}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.test}
                  </StyledTableCell>*/}
                  {/* <StyledTableCell className="table-cell">{row.minTime}</StyledTableCell> */}
                  <StyledTableCell className="table-cell">
                    {row.frcdCycleTime}
                  </StyledTableCell>
                  {/* <StyledTableCell className="table-cell">{row.fPartname}</StyledTableCell> */}
                  {/* <StyledTableCell className="table-cell">
                    {row.fPartName}
                  </StyledTableCell> */}
                <StyledTableCell className="table-cell">
                      <Button
                        onClick={() => handlePartNameClick(row.fPartName, row.mid)}
                        variant="text"
                        color="primary"
                        style={{
                          backgroundColor: "#1FAEC5",
                          marginBottom: "5px",
                          color: "black",
                          lineHeight: 1,
                          padding: "8px 8px",
                        }}
                      >
                        {/* {row.partNameM2} */}
                        View Part Names

                      </Button>
                    </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.fCycleTime}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.category}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.aLoss}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.pLoss}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {/* {emptyRows > 0 && (
  <StyledTableRow style={{ height: 53  }}>
     <StyledTableCell colSpan={8} style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>
        {`No further data available`}
      </div>
    </StyledTableCell>
  </StyledTableRow>
)} */}
          </TableBody>
        </Table>
          )}

      </Box>
      <Modal open={openPartNamesDialog} onClose={handleClosePartNamesDialog}>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
    maxHeight: '80vh',
    borderRadius: "10px",
    overflow: 'hidden',
  }}>
    <IconButton
      aria-label="close"
      onClick={handleClosePartNamesDialog}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: 'white',
        zIndex: 1,
      }}
    >
      <CloseIcon />
    </IconButton>
    <Typography 
      id="part-names-modal-title" 
      variant="h6" 
      component="h2" 
      sx={{ 
        textAlign: 'center', 
        backgroundColor: '#1FAEC5', 
        color: 'white', 
        p: 2,
        fontWeight: 'bold',
      }}
    >
      Part Names
    </Typography>
    <TableContainer component={Paper} sx={{ maxHeight: 440, boxShadow: 'none', marginTop: '0' }}>
      <Table stickyHeader aria-label="part names table">
        <TableBody>
          {partNamesData && partNamesData.partNames && 
            chunk(partNamesData.partNames.split(','), 3).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((partName, cellIndex) => (
                  <TableCell 
                    key={cellIndex}
                    sx={{
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '12px',
                      textAlign: 'center',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                      fontWeight: '600',
                      width: '33.33%',
                    }}
                  >
                    {partName.trim()}
                  </TableCell>
                ))}
                {row.length < 3 && [...Array(3 - row.length)].map((_, index) => (
                  <TableCell 
                    key={`empty-${index}`}
                    sx={{
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '12px',
                      width: '33.33%',
                    }}
                  />
                ))}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
</Modal>
    
        <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
