import React, { useEffect, useRef, useState } from 'react';
import { select, scaleTime, scaleBand, axisBottom, axisLeft, timeFormat, timeMinute } from 'd3';
import { FormControl, InputLabel, Select, MenuItem, TextField, Grid, Button, Snackbar, CircularProgress, Backdrop } from "@mui/material";
import { apigetLines } from '../api/api.getline';
import { useAuthCheck } from '../utils/Auth';
import { apiMachineStatus } from '../api/api.machineStatus';

const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};

const GanttChartComponent = () => {
  const [lineData, setLineData] = useState([]);
  const [rawData, setRawData] = useState({
    lineNo: "",
    fromDate: getCurrentDate(),
  });
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const svgRef = useRef();

  useAuthCheck();

  useEffect(() => {
    const getLine = async () => {
      try {
        const result = await apigetLines();
        setLineData(result.data.data);
      } catch (error) {
        setError(error.message);
        setSnackbarMessage(error.message);
        setOpenSnackbar(true);
      }
    };
    getLine();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRawData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOkButton = async () => {
    setLoading(true); 
    try {
      const result = await apiMachineStatus(rawData);
      const data = result.data.map(item => ({
        machineName: item.machineName,
        start: new Date(item.datetime),
        end: new Date(new Date(item.datetime).getTime() + 30 * 60000), // assuming 30 minutes duration
        status: item.alertStatus,
      }));
      console.log("machine data:", result.data);
      console.log("data:", data);

      setChartData(data);
      drawChart(data);
    } catch (error) {
      setError(error.message);
      setSnackbarMessage(error.message);
      setOpenSnackbar(true);
    } finally {
      setLoading(false); 
    }
  };

  const drawChart = (data) => {
    const svg = select(svgRef.current);
    const margin = { top: 10, right: 20, bottom: 50, left: 180 };
    const width = 1300 - margin.left - margin.right;
    const height =350 - margin.top - margin.bottom;

    const x = scaleTime()
      .domain([new Date(Math.min(...data.map(d => d.start))), new Date(Math.max(...data.map(d => d.end)))])
      .range([0, width]);

    const y = scaleBand()
      .domain(data.map(d => d.machineName))
      .range([height, 0])
      .padding(0.1);

    svg.selectAll('*').remove();

    const xAxisFormat = timeFormat('%H:%M');
//tum batao ????
    svg.append('g')
      .attr('transform', `translate(${margin.left},${height + margin.top})`)
      .call(axisBottom(x)
        .ticks(timeMinute.every(30)) 
        .tickFormat(xAxisFormat))
      .selectAll("text")
      .attr("transform", "rotate(-45)") 
      .style("text-anchor", "end")  
      .style("font-size", "12px");

    svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .call(axisLeft(y))
      .attr('class', 'y-axis')
      .selectAll("text")
      .style("font-size", "12px"); 

    svg.selectAll('.bar')
      .data(data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.start))
      .attr('y', d => y(d.machineName))
      .attr('width', d => x(d.end) - x(d.start))
      .attr('height', y.bandwidth())
      // .attr('opacity', 0.8)  
      .attr('fill', d => d.status === 'red' ? 'red' : 'green')
      .attr('transform', `translate(${margin.left},${margin.top})`);
  };

  return (
    <div style={{ padding: "0px 20px", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "flex-start", margin: "20px 0px 20px 0px" }}>
        <h2>Machine Availability Chart</h2>
      </div>
      <Grid container spacing={2} style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Plant</InputLabel>
            <Select
              name="lineNo"
              value={rawData.lineNo}
              onChange={handleInputChange}
            >
              {lineData.map((line) => (
                <MenuItem key={line.id} value={line.lineNo}>
                  {line.lineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date"
              name="fromDate"
              type="date"
              placeholder="Start Date"
              value={rawData.fromDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleOkButton}>
            OK
          </Button>
        </Grid>
      </Grid>
      <svg ref={svgRef} width="1400" height="500" style={{ border: "1px solid black" }}></svg>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default GanttChartComponent;
