import React, { useEffect, useState } from "react";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Button,
  Grid,
  tableCellClasses,
  styled,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { apigetMachine } from "../api/apigetmachine";
import { apigetLines } from "../api/api.getline";
import { apigetRawData } from "../api/api.getrawdata";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import { useAuthCheck } from "../utils/Auth";
import DownloadButton from "../utils/DownloadButton";
import "../assets/css/forms.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};
export default function Rawpathrediope() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [rawData, setRawData] = useState({
    lineNo: "",
    machineId: "",
    fromDate: getCurrentDate(),
    toDate: getCurrentDate(),
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedLine, setSelectedLine] = useState("");
  const [loading, setLoading] = useState(false);
  useAuthCheck();
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("Result data machine:", result.data.data);
        setMachineData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getmachine();
  }, [refreshData]);
  useEffect(() => {
    const getLine = async () => {
      try {
        const result = await apigetLines();
        console.log("Result data line:", result.data.data);
        setLineData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getLine();
  }, [refreshData]);

  const handleInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    setRawData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formattedFromDate = format(
        parseISO(rawData.fromDate),
        "dd-MMM-yyyy"
      );
      const formattedToDate = format(parseISO(rawData.toDate), "dd-MMM-yyyy");
      console.log(
        "todate,fromdate,machineid,lineid:",
        formattedToDate,
        formattedFromDate
      );
      const formattedRawData = {
        ...rawData,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      };
      // setAddOpen(false);
      console.log("formatted raw data:", formattedRawData);
      const result = await apigetRawData(formattedRawData);

      // await getmachine();
      handleSnackbarOpen("Raw Data fetched successfully!", "success"); 
      // setLoading(false);
      console.log("Raw response", result.data);
      setData(result.data);
      setRefreshData((prev) => !prev);
    } catch (error) {
      // setLoading(false);
      console.error("Error getting raw data:", error);
      handleSnackbarOpen("Error fetching raw data. Please try again.", "error"); // Pass severity as "error"
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      "Machine Id": row.machineId !== null ? row.machineId : "",
      "Date Time": row.dateTime !== null ? row.dateTime : "",
      "Cycle Time": row.cycleTime !== null ? row.cycleTime : "",
    }));
  };

  const downloadApiCall = async () => {
    const { lineNo, machineId, fromDate, toDate } = rawData;
    const formattedFromDate = format(parseISO(fromDate), "dd-MMM-yyyy");
    const formattedToDate = format(parseISO(toDate), "dd-MMM-yyyy");

    return await apigetRawData({
      lineNo,
      machineId,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    });
  };
  const filteredMachines = machineData.filter(
    (machine) => machine.lineNo === selectedLine
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  return (
    <div style={{ padding: "0px 20px", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "20px 0px 20px 0px",
        }}
      >
        <h2>Raw Data </h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
      >
        {" "}
        <Grid item xs={6} sm={3}>
          {" "}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Plant</InputLabel>
            <Select
              name="lineNo"
              value={rawData?.lineNo}
              onChange={handleInputChange}
            >
              {lineData.map((line) => (
                <MenuItem key={line.id} value={line.lineNo}>
                  {line.lineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Machine</InputLabel>

            <Select
              name="machineId"
              value={rawData?.machineId}
              onChange={handleInputChange}
            >
              {filteredMachines.map((machine) => (
                <MenuItem key={machine.id} value={machine.machineId}>
                  {machine.displayMachineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          {" "}
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date"
              name="fromDate"
              type="date"
              placeholder="Start Date"
              value={rawData?.fromDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          {" "}
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="End Date"
              name="toDate"
              type="date"
              value={rawData?.toDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          {" "}
          <Button variant="contained" color="primary" onClick={handleAddSubmit}>
            OK
          </Button>
        </Grid>
      </Grid>
      <DownloadButton
        apiCall={downloadApiCall}
        formatData={formatData}
        fileName="RawDataReport.xlsx"
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 1000]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
        nextIconButtonProps={{
          onClick: () => handleChangePage(null, page + 1),
          disabled: page === Math.ceil(data.length / rowsPerPage) - 1,
        }}
        backIconButtonProps={{
          onClick: () => handleChangePage(null, page - 1),
          disabled: page === 0,
        }}
        SelectProps={{
          native: true,
          SelectDisplayProps: { "aria-label": "Rows per page" },
        }}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} of ${count}`
        }
        nextIconButtonText="Next"
        backIconButtonText="Previous"
      />
      <Box sx={{ maxHeight: "60vh", overflow: "auto",marginBottom:'40px' }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              marginBottom: "40px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            size="small"
            style={{
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">
                  Machine
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Date 
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Time 
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Shift 
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Cycle Time
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.machineId}</StyledTableCell>
                    <StyledTableCell>{row.spilitDate}</StyledTableCell>
                    <StyledTableCell>{row.spilitTime}</StyledTableCell>
                    <StyledTableCell>{row.shiftName}</StyledTableCell>


                    <StyledTableCell>{row.cycleTime}</StyledTableCell>
                  </StyledTableRow>
                ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 }}>
                  <StyledTableCell colSpan={8} style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {`No further data available`}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
