import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiMachineMaster } from "../api/api.addmachine";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { apigetMachine } from "../api/apigetmachine";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { apiGetMachineInput } from "../api/api.getmachineinput";
import { apiGetPart } from "../api/api.getpart";
import { apiUpdateMachineInput } from "../api/api.updatemachineinput";
import { useAuthCheck } from "../utils/Auth";
import { apigetLines } from "../api/api.getline";
import { apiViewMultipleParts } from "../api/api.viewmultipleparts";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MachineInput() {
  const [machineInputData, setMachineInputData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [viewMPAdd, setViewMPAdd] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  const [partData, setPartData] = useState([]);
  const [partData2, setPartData2] = useState([]);

  const [selectedPlant, setSelectedPlant] = useState("");
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [selectedLineName, setSelectedLineName] = useState("");
  const [machineData, setMachineData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [selectedLine, setSelectedLine] = useState("");
  const [viewMPResultOpen, setViewMPResultOpen] = useState(false);

  const [error, setError] = useState(null);

  const [updatedData, setUpdatedData] = useState({
    id: "",
    partId: "",
    partNo: "",
    cycleTime: "",
  });

  const [mPartsData, setMPartsData] = useState({
    lineNo: "",
    machineNo: "",
    partName: "",
  });

  const [partsData, setPartsData] = useState([]);

  useAuthCheck();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [machineInputResult] = await Promise.all([apiGetMachineInput()]);
        const machineInputData1 = machineInputResult.data.data;
        console.log("machine input data:", machineInputData1);
        setMachineInputData(machineInputData1);
      } catch (error) {
        handleSnackbarOpen(error.message, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [refreshData]);
  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("Result data machine:", result.data.data);
        setMachineData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getmachine();
  }, [refreshData]);
  useEffect(() => {
    const getLine = async () => {
      try {
        const result = await apigetLines();
        console.log("Result data line:", result.data.data); // Log the data property
        setLineData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getLine();
  }, [refreshData]);
  useEffect(() => {
    const getParts = async () => {
      try {
        const result = await apiGetPart();
        console.log("part data:", result.data.data);
        setPartData(result.data.data);
      } catch (error) {
        handleSnackbarOpen(error.message, "error");
      }
    };
    getParts();
  }, [refreshData]);
  const handleViewMPartsChange = (e) => {
    const { name, value } = e.target;
    setMPartsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "partNo") {
      const selectedPart = partData.find((part) => part.partNo === value);
      if (selectedPart) {
        setUpdatedData((prevData) => ({
          ...prevData,
          partId: selectedPart.partId,
          cycleTime: selectedPart.cycleTime,
        }));
      }
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const result = await apiUpdateMachineInput(updatedData);
      setAddOpen(false);

      handleSnackbarOpen("Machine input updated successfully!", "success");
      console.log("response", result.data);
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error adding machine:", error);
      handleSnackbarOpen(
        "Error adding machine input. Please try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (row) => {
    setUpdatedData({
      id: row.id,
      partId: row.partId,
      cycleTime: row.cycleTime || "",
      partNo: row.partNo || "",
    });
    setSelectedLineName(row.lineName);
    setSelectedMachine(row.displayMachineName);
    console.log(
      "selected plant:",
      row.lineName,
      "selected machine:",
      row.displayMachineName
    );
    setAddOpen(true);
  };

  const handleModalClose = () => {
    setUpdatedData({
      id: "",
      partId: "",
      cycleTime: "",
      partNo: "",
    });
    setAddOpen(false);
    setViewMPAdd(false);
    setViewMPResultOpen(false);
  };

  useEffect(() => {
    console.log(
      "selectedPlant:",
      selectedPlant,
      "selectedMachine:",
      selectedMachine
    );
    console.log("partData:", partData);
  }, [selectedPlant, selectedMachine, partData]);
  const filteredMachines = machineData.filter(
    (machine) => machine.lineNo === mPartsData.lineNo
  );

  const filteredParts2 = partData.filter(
    (part) =>
      part.lineNo === mPartsData.lineNo &&
      part.machineNo === mPartsData.machineNo
  );

  const filteredParts = partData.filter(
    (part) =>
      part.lineName === selectedLineName &&
      part.displayMachineName === selectedMachine
  );

  const handlMultiplePartsClick = async (event) => {
    event.preventDefault();
    setLoading(true); 
    try {
      const result = await apiViewMultipleParts(mPartsData);
      console.log("multiple parts data:", result.data);
      handleSnackbarOpen(
        "Multiple parts data fetched successfully!",
        "success"
      );
      setPartsData(result.data); 
      setViewMPResultOpen(true); 
    } catch (error) {
      console.error("Error fetching multiple parts data:", error);
      handleSnackbarOpen(
        "Error fetching multiple parts data. Please try again.",
        "error"
      );
    } finally {
      setLoading(false); 
    }
  };
  const handleVMPClick = () => {
    setViewMPAdd(true);
  };
  useEffect(() => {
    console.log("filteredParts:", filteredParts);
  }, [filteredParts]);

  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <h2>Machine Input</h2>

        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#1FAEC5" }}
            onClick={handleVMPClick}
          >
            View Multiple Parts
          </Button>
        </div>
      </div>
      <Box sx={{ maxHeight: "500px", overflow: "auto" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">
                  Plant Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Machine Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Running Part Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Standard Cycle Time in secs 
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Select Part
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {machineInputData?.length > 0 ? (
                machineInputData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className="table-cell">
                      {row.lineName}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.displayMachineName}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partName}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.cycleTime}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      <IconButton onClick={() => handleEditClick(row)}>
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} style={{ textAlign: "center" }}>
                    No data available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Box>
      <Modal open={addOpen} onClose={handleModalClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            minWidth: "500px",
          }}
        >
          <button
            onClick={handleModalClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "30px",
            }}
          >
            &times;
          </button>
          <h2>Machine Input</h2>
          <hr />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <FormControl sx={{ width: "26ch" }}>
              <InputLabel>Part Name</InputLabel>
              <Select
                name="partNo"
                value={updatedData.partNo || ""}
                onChange={handleInputChange}
              >
                {filteredParts.length > 0 ? (
                  filteredParts.map((part) => (
                    <MenuItem key={part.id} value={part.partNo}>
                      {part.partName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No parts available</MenuItem>
                )}
              </Select>
            </FormControl>

            <TextField
              value={updatedData.cycleTime}
              name="cycleTime"
              onChange={handleInputChange}
              label="Standard Cycle Time in secs"
              style={{ width: "15rem", marginLeft: "10px" }}
            />
          </div>
          <Button
            onClick={handleAddSubmit}
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Add
          </Button>
        </div>
      </Modal>
      <Modal open={viewMPResultOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            width: "800px",
            maxHeight: "80vh",
            overflow: "auto",
            borderRadius: "10px",
          }}
        >
          <h2>Fetched Parts Data</h2>
          <button
            onClick={handleModalClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "30px",
            }}
          ></button>
          {loading ? (
            <CircularProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Part Name</StyledTableCell>
                  <StyledTableCell>Machine Name</StyledTableCell>
                  <StyledTableCell>Part ID</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partsData.map((part, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{part.partName}</StyledTableCell>
                    <StyledTableCell>{part.machineName}</StyledTableCell>
                    <StyledTableCell>{part.partId}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Modal>

      <Modal open={viewMPAdd} onClose={handleModalClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            minWidth: "500px",
          }}
        >
          <button
            onClick={handleModalClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "30px",
            }}
          >
            &times;
          </button>
          <h2>View Multiple Parts</h2>
          <hr />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <FormControl sx={{ width: "26ch" }}>
              <InputLabel>Select Plant</InputLabel>
              <Select
                name="lineNo"
                value={mPartsData.lineNo || ""}
                onChange={handleViewMPartsChange}
              >
                {lineData.map((line) => (
                  <MenuItem key={line.id} value={line.lineNo}>
                    {line.lineName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: "26ch" }}>
              <InputLabel>Select Machine</InputLabel>
              <Select
                name="machineNo"
                value={mPartsData.machineNo || ""}
                onChange={handleViewMPartsChange}
              >
                {filteredMachines.map((machine) => (
                  <MenuItem key={machine.id} value={machine.machineNo}>
                    {machine.displayMachineName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ width: "26ch" }}>
              <InputLabel>Select Part</InputLabel>
              <Select
                name="partName"
                value={mPartsData.partName || ""}
                onChange={handleViewMPartsChange}
              >
                {filteredParts2.length > 0 ? (
                  filteredParts2.map((part) => (
                    <MenuItem key={part.id} value={part.partName}>
                      {part.partName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No parts available</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <Button
            onClick={handlMultiplePartsClick}
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            View
          </Button>
        </div>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
