import React from 'react';
import { Box, CircularProgress, Typography, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';

const OverlayContainer = styled(Box)(({ theme, variant }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
  ...(variant === 'blur' && {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(3px)',
  }),
  ...(variant === 'dim' && {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  }),
  ...(variant === 'card' && {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
  }),
  ...(variant === 'minimal' && {
    backgroundColor: 'transparent',
  }),
}));

const MessageText = styled(Typography)(({ theme, overlayvariant }) => ({
  marginTop: theme.spacing(2),
  ...(overlayvariant === 'dim' && {
    color: 'white',
  }),
}));

const LoadingOverlay = ({
  message = "Loading...",
  size = 60,
  color = "primary",
  variant = 'blur', // 'blur' | 'dim' | 'card' | 'minimal'
  fadeIn = true,
  children
}) => {
  const content = (
    <OverlayContainer variant={variant}>
      <CircularProgress 
        size={size} 
        color={variant === 'dim' ? 'inherit' : color}
        sx={variant === 'dim' ? { color: 'white' } : undefined}
      />
      {message && (
        <MessageText 
          variant="h6" 
          overlayvariant={variant}
        >
          {message}
        </MessageText>
      )}
      {children}
    </OverlayContainer>
  );

  return fadeIn ? (
    <Fade in={true} timeout={300}>
      {content}
    </Fade>
  ) : content;
};

export default LoadingOverlay;