import axios from "axios";
import { baseUrl } from "./baseUrl";

export const apiGetOeeDashboardM2 = async ( lineNo,machineNo, dataType) => {
  const url = baseUrl + "/common/opeM2DetailByPeriod";
  try {
    const token = localStorage.getItem("token");
    console.log(machineNo, lineNo, dataType);
    const data = await axios.post(
      url,
      {
        lineNo: lineNo,
        machineNo: machineNo,
        period: dataType,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error during adding line:", error);
    throw error;
  }
};