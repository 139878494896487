import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import { apigetLines } from "../api/api.getline";
import { apigetMachine } from "../api/apigetmachine";
import { apiGetSprlDashboard } from "../api/api.getsprldashboard";
import "../assets/css/oeedashboard.css";
ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const OEEDashboard = () => {
  const [machineData, setMachineData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    lineNo: "",
    machineNo: "",
    dataType: "hourly",
  });
  const [selectedLine, setSelectedLine] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [chartData, setChartData] = useState({
    VatData: null,
    OpeData: null,
    OEEData: null,
  });
  const [gaugeData, setGaugeData] = useState({
    availabilityPercentage: 0,
    performancePercentage: 0,
    qualityPercentage: 0,
    oeePercentage: 0,
  });

  const { locationId } = useLocation().state || {};

  const handleSnackbarOpen = useCallback((message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  }, []);

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDashboardData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  const processChartData = useCallback((data, timeFrame) => {
    if (!data || data.length === 0) {
      return { VatData: null, OpeData: null, OEEData: null };
    }
  
    // const parseDate = (dateString) => {
    //   // Split dateString by space and then by slashes and colons
    //   const [datePart, timePart] = dateString.split(' ');
    //   const [day, month, year] = datePart.split('/');
    //   const [hours, minutes, seconds] = timePart.split(':');
      
    //   // Create a new Date object
    //   const date = new Date(year, month - 1, day, hours, minutes, seconds);
      
    //   if (isNaN(date.getTime())) {
    //     console.error("Invalid Date:", dateString);
    //     return new Date(NaN); // Return invalid date
    //   }
      
    //   console.log("Parsed Date:", date); // Debugging
    //   return date;
    // };


    const parseDate = (dateString) => {
      const [datePart, timePart] = dateString.split(' ');
      const [day, month, year] = datePart.split('/');
      const [hours, minutes, seconds] = timePart.split(':');
      
      // Adjust for the time part in milliseconds
      const milliseconds = seconds.split('.')[1] || 0;
    
      const date = new Date(year, month - 1, day, hours, minutes, parseInt(seconds, 10), parseInt(milliseconds, 10));
      
      if (isNaN(date.getTime())) {
        console.error("Invalid Date:", dateString);
        return new Date(NaN); // Return invalid date
      }
      
      console.log("Parsed Date:", date); // Debugging
      return date;
    };
    
    
    const groupData = (items, timeUnit) => {
      return items.reduce((acc, item) => {
        const date = parseDate(item.dateTime);
        let key;
        switch (timeUnit) {
          case "hourly":
            key = date.setMinutes(0, 0, 0);
            break;
          case "day":
            key = date.setHours(0, 0, 0, 0);
            break;
          case "week":
            const startOfWeek = new Date(date);
            startOfWeek.setDate(date.getDate() - date.getDay()); // Adjust to start of the week
            key = startOfWeek.setHours(0, 0, 0, 0);
            break;
          case "month":
            key = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
            break;
          default:
            key = date.setMinutes(0, 0, 0);
        }
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
    };
    
    const groupedData = groupData(data, timeFrame);
    console.log("Grouped Data:", groupedData); // Debugging
  
    const processedData = Object.entries(groupedData).map(([timestamp, items]) => ({
      timestamp: parseInt(timestamp),
      vat: items.reduce((sum, item) => sum + parseFloat(item.vat), 0) / items.length,
      ope: items.reduce((sum, item) => sum + parseFloat(item.opeC1), 0) / items.length,
      oee: items.reduce((sum, item) => sum + parseFloat(item.oee), 0) / items.length,
    }));
  
    processedData.sort((a, b) => a.timestamp - b.timestamp);
    console.log("Processed Data:", processedData); // Debugging
  
    const labels = processedData.map((item) => new Date(item.timestamp));
  
    const createDataset = (label, data, color) => ({
      labels,
      datasets: [
        {
          label,
          data,
          borderColor: color,
          backgroundColor: color.replace("rgb", "rgba").replace(")", ", 0.5)"),
        },
      ],
    });
  
    return {
      VatData: createDataset(
        "VAT",
        processedData.map((item) => item.vat),
        "rgb(255, 99, 132)"
      ),
      OpeData: createDataset(
        "OPE",
        processedData.map((item) => item.ope),
        "rgb(53, 162, 235)"
      ),
      OEEData: createDataset(
        "OEE",
        processedData.map((item) => item.oee),
        "rgb(75, 192, 192)"
      ),
    };
  }, []);
  
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { lineNo, machineNo, dataType } = dashboardData;
      const result = await apiGetSprlDashboard(lineNo, machineNo, dataType);
      console.log("data from api:", result.data.data);
      if (!result.data.data || result.data.data.length === 0) {
        handleSnackbarOpen(
          "No data available for the selected criteria",
          "warning"
        );
        setChartData({ VatData: null, OpeData: null, OEEData: null });
        setGaugeData({
          availabilityPercentage: 0,
          performancePercentage: 0,
          qualityPercentage: 0,
          oeePercentage: 0,
        });
      } else {
        const processedData = processChartData(result.data.data, dataType);
        const pData = {
          VatData: {
            ...processedData.VatData,
            datasets: processedData.VatData.datasets.map((dataset) => ({
              ...dataset,
              data: dataset.data,
            })),
          },
          OpeData: {
            ...processedData.OpeData,
            datasets: processedData.OpeData.datasets.map((dataset) => ({
              ...dataset,
              data: dataset.data,
            })),
          },
          OEEData: {
            ...processedData.OEEData,
            datasets: processedData.OEEData.datasets.map((dataset) => ({
              ...dataset,
              data: dataset.data,
            })),
          },
        };
        setChartData(pData);
        const latestData = result.data.data[result.data.data.length - 1];
        setGaugeData({
          availabilityPercentage: parseFloat(latestData.aPer) / 100,
          performancePercentage: parseFloat(latestData.pPer) / 100,
          qualityPercentage: parseFloat(latestData.qPer) / 100,
          oeePercentage: parseFloat(latestData.uPer) / 100,
        });
      }

      setError(null);
    } catch (error) {
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  }, [dashboardData, handleSnackbarOpen, processChartData]);

  useEffect(() => {
    const fetchLineData = async () => {
      try {
        const result = await apigetLines();
        setLineData(result.data.data);
      } catch (error) {
        handleSnackbarOpen(error.message, "error");
      }
    };
    fetchLineData();
  }, [handleSnackbarOpen]);

  useEffect(() => {
    if (selectedLine) {
      const fetchMachineData = async () => {
        try {
          const result = await apigetMachine();
          setMachineData(result.data.data);
        } catch (error) {
          handleSnackbarOpen(error.message, "error");
        }
      };
      fetchMachineData();
    }
  }, [selectedLine, handleSnackbarOpen]);

  const filteredMachines = useMemo(
    () => machineData.filter((machine) => machine.lineNo === selectedLine),
    [machineData, selectedLine]
  );
  const chartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
          time: {
            unit:
              dashboardData.dataType === "hourly"
                ? "hour"
                : dashboardData.dataType === "day"
                ? "day"
                : dashboardData.dataType === "week"
                ? "week"
                : "month",
            displayFormats: {
              hour: "HH:mm",
              day: "MMM d yyyy",
              week: "MMM d",
              month: "MMM yyyy",
            },
          },
          title: {
            display: true,
            text: "Time",
            color: "white",
          },
          ticks: {
            color: "white",
            maxRotation: 45,
            minRotation: 45,
          },
          grid: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
        y: {
          title: {
            display: true,
            text: "Value",
            color: "white",
          },
          ticks: {
            color: "white",
          },
          grid: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            color: "white",
            font: {
              size: 12,
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "xy",
          },
        },
      },
    }),
    [dashboardData.dataType]
  );

  const renderChart = useCallback(
    (data, title) => (
      <Paper
        style={{
          paddingTop: 15,
          paddingBottom: 35,
          paddingLeft: 15,
          paddingRight: 15,
          backgroundColor: "rgba(3, 3, 62, 0.9)",
          height: "300px",
        }}
      >
        <Typography variant="h6" align="center" style={{ color: "white" }}>
          {title}
        </Typography>
        {data ? (
          <Line data={data} options={chartOptions} />
        ) : (
          <Typography variant="body1" align="center" style={{ color: "white" }}>
            {isLoading
              ? `Loading ${title}...`
              : `No ${title} available. Please check your selection or try again.`}
          </Typography>
        )}
      </Paper>
    ),
    [chartOptions, isLoading]
  );

  const renderGauge = useCallback(
    (percent, title, colors) => (
      <Paper
        elevation={3}
        style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
      >
        <Typography variant="h6" align="center" style={{ color: "white" }}>
          {title}
        </Typography>
        <GaugeChart
          id={`${title.toLowerCase()}-gauge`}
          nrOfLevels={10}
          percent={percent}
          colors={colors}
          arcWidth={0.3}
          needleColor="white"
        />
      </Paper>
    ),
    []
  );

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: "5px",
          paddingBottom: "10px",
        }}
      >
        <h2>OEE Dashboard</h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item xs={3}>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel>Select Plant</InputLabel>
            <Select
              name="lineNo"
              value={dashboardData.lineNo}
              onChange={handleInputChange}
            >
              {lineData?.map((line) => (
                <MenuItem key={line.id} value={line.lineNo}>
                  {line.lineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel>Select Machine</InputLabel>
            <Select
              name="machineNo"
              value={dashboardData.machineNo}
              onChange={handleInputChange}
              disabled={!selectedLine}
            >
              {filteredMachines?.map((machine) => (
                <MenuItem key={machine.id} value={machine.machineNo}>
                  {machine.displayMachineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel>Select Data Type</InputLabel>
            <Select
              name="dataType"
              value={dashboardData.dataType}
              onChange={handleInputChange}
            >
              <MenuItem value="hourly">Hourly</MenuItem>
              <MenuItem value="day">Daily</MenuItem>
              <MenuItem value="week">Weekly</MenuItem>
              <MenuItem value="month">Monthly</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchData}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "OK"}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
  {renderGauge(gaugeData.availabilityPercentage, "Availability", [
    "#FF0000",  
    "#00FF00"   
  ])}
</Grid>
<Grid item xs={3}>
  {renderGauge(gaugeData.performancePercentage, "Performance", [
    "#FF0000",  
    "#00FF00"   
  ])}
</Grid>
<Grid item xs={3}>
  {renderGauge(gaugeData.qualityPercentage, "Quality", [
    "#FF0000",  
    "#00FF00"   
  ])}
</Grid>
<Grid item xs={3}>
  {renderGauge(gaugeData.oeePercentage, "Utilization", [
    "#FF0000",  
    "#00FF00"   
  ])}
</Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item xs={4}>
          {renderChart(chartData.VatData, "VAT ")}
        </Grid>
        <Grid item xs={4}>
          {renderChart(chartData.OpeData, "OPE ")}
        </Grid>
        <Grid item xs={4}>
          {renderChart(chartData.OEEData, "OEE ")}
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OEEDashboard;



