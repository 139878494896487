import React, { useState } from "react";
// import "../style/login.css";
import '../assets/css/Login.css'
import { apiLogin } from "../api/Api.login";
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
export default function Login() {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error,setError]=useState(null)
  const [severity, setSeverity] = useState("success")

  const navigate=useNavigate()
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity); 
    setOpenSnackbar(true);
  };
  const handleSubmit = async(event) => {
    event.preventDefault();
    try {
      const result= await apiLogin(userName, password);
    console.log("response",result.data);
    localStorage.setItem('token', result.data.data.token);
    localStorage.setItem('userID', result.data.data.userId);

    

    localStorage.setItem('refreshToken', result.data.data.refreshToken);
    localStorage.setItem('tokenExpiredAt', result.data.data.tokenExpiredAt);
    console.log("Submitted:", { userName, password });
    if(result.data.status==='success'){
      navigate("/welcome")
    }
    } catch (error) {
      console.error("Error adding Line:", error);
      handleSnackbarOpen("Error loggin in. Please try again.", "error");
    }
    
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-offset-4 col-md-4 col-sm-offset-3 col-sm-6">
            <div className="form-container">
              <div className="right-content">
                <h3 className="form-title">Login</h3>
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userName}
                      onChange={handleUsernameChange}
                      placeholder="Enter Username"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter your password"
                      required
                    />
                  </div>
                  <div className="remember-me">
                    <input type="checkbox" className="checkbox" />
                    <span className="check-label">Remember Me</span>
                  </div>
                  <a href="" className="forgot">
                    Forgot Password
                  </a>
                  <button type="submit" className="btn signin signinpointer">
                    Login
                  </button>
                  
                </form>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  className="snackbar-right"
  onClose={() => setOpenSnackbar(false)}
>
  <MuiAlert
    onClose={() => setOpenSnackbar(false)}
    severity={severity} 
    sx={{ width: '100%' }}
  >
    {snackbarMessage}
  </MuiAlert>
</Snackbar>
    </div>
  );
}